<template>
  <div>
   <Nav ref="navchild"></Nav>
    <div class="banner-width"></div>
    <div class="mainwidth">
      <Title>
        <template v-slot:one>
          <el-divider content-position="center">云科普展厅</el-divider>
        </template>
        <template v-slot:two>
          <span class="sub">科技改变生活</span>
        </template>
      </Title>

      <div class="content"> 
        <div class="contenttitle">{{theme}}</div>
        <div style="display: flex;
  justify-content: center;">
          <div class="source">
            作者：{{author }}
            <el-divider direction="vertical"></el-divider>
          </div>

          <div class="date">单位：{{unit }}</div>
        </div>
        <div class="center" v-html="exhibitionProfile">{{exhibitionProfile}}</div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Nav from "@/views/pc/components/Nav";
import Footer from "@/views/pc/components/Footer";
import Title from "@/views/pc/components/Title";
import { ykpzdListDetails } from "@/api/pc.js";
import { appTitle } from '@/utils/get-page-title.js'
export default {
  name: "TechLifeDetail",
  data() {
    return {
      id: this.$route.params.id,
      theme: "",
      unit: "",
      exhibitionProfile: "",
      author: ""
    };
  },
  components: { Nav, Footer, Title },
    created(){
    this.getYkpztDetails()
  },
    mounted() {
    var arr = this.$route.path.split("/");
    this.$refs.navchild.setNavIndex("/" + arr[1]);
  },
    methods: {
    getYkpztDetails() {
      let params = {
        id: this.id
      };
      ykpzdListDetails(params).then(res => {
        this.theme = res.data.theme;
        document.title = res.data.theme + '-' + appTitle
        this.releaseTime = res.data.releaseTime;
        this.exhibitionProfile = res.data.exhibitionProfile;
        this.author = res.data.author;
        this.unit = res.data.unit;
      });
    }
  }
};
</script>

<style scoped lang="scss">
.el-divider {
  background: linear-gradient(
    to right,
    #fff,
    #e2e4e9,
    #d8d8d8,
    #d8d8d8,
    #e2e4e9,
    #fff
  );
}
.banner-width {
  height: 330px;
  background: url("~@/assets/pcimg/sh.png") center no-repeat;
  margin-top: 124px;
}
.el-divider__text {
  background: #fff;
}
.mainwidth {
  width: 1200px;
  margin: 0 auto;

.content {
    min-height: calc(100vh - 412px);
    margin-top: 40px;
    .contenttitle {
      font-size: 36px;
      font-weight: bold;
      text-align: center;
    }
    .date {
      font-size: 18px;
      color: #999;
      padding-top: 10px;
      text-align: center;
    }

    .source {
      font-size: 18px;
      color: #999;
      padding-top: 10px;
      text-align: center;
      padding-right: 8px;
    }
    .center {
      line-height: 30px;
      margin-top: 20px;
      img {
        margin-top: 20px;
      }
    }
  }
  .list {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
</style>